import React from "react";
import { Toggle } from "react-powerplug";
import styled from "styled-components";
//
import Logo from "./logo";
import Navigation from "./menu";
//
export default ({ ...rest }) => (
  <Toggle initial={false}>
    {({ on, toggle }) => (
      <Wrap mobileActive={on}>
        <Item>
          <Logo mobileActive={on} onClick={toggle} />
        </Item>
        <Navigation mobileActive={on} onClick={toggle} {...rest} />
      </Wrap>
    )}
  </Toggle>
);
//

const Wrap = styled.header`
  display: flex;
  background-color: black;
  flex-direction: column;
  margin: 0 auto;
  padding: 1rem 0;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 2000;

  @media (min-width: 768px) {
    padding: 2rem 0;
  }

  a {
    color: #dfe2ea;
    :hover,
    &.active {
      color: #847b4a;
    }
  }
`;

const Item = styled.div`
  display: flex;
  z-index: 2001;
`;
