import React from "react";
import styled from "styled-components";
//
import { RichText } from "components/prismic";
import Footer from "./footer";

//
export default ({ image, title, bio, ...rest }) => (
  <Wrap>
    <img src={image.url} alt={title} />
    <Title>
      <h1>Eliya Zweygberg</h1>
    </Title>
    <p>
      <RichText {...bio} />
    </p>
    <Footer {...rest} />

    {/* <pre>
      <code>{JSON.stringify(rest, null, 2)}</code>
    </pre> */}
  </Wrap>
);
//
const Wrap = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  padding-bottom: 1.5rem;

  img {
    max-height: 80vh;
    padding-top: 2rem;
  }

  p {
    max-width: 540px;
    padding: 0.25rem;
    text-align: justify;

    p {
      margin: 0.25rem 0 1rem 0;
    }
  }

  h3 {
    font-size: 40px;
    margin: 0 auto;
    vertical-align: center;
  }
`;
const Title = styled.div`
  margin: 0 auto;
  max-width: 100%;
  padding: 0;
  text-transform: uppercase;
  font-size: 1.3em;
`;
