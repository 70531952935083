import React from "react";
import styled from "styled-components";
//
import { RichText } from "components/prismic";
import Instagram from "components/Instagram";
import Facebook from "components/Facebook";
import Mail from "components/Mail";

//
export default ({ cv, facebook, instagram, email, ...rest }) => (
  <Wrap>
    <Links>
      <Link>
        <a href={cv.url} target="_blank">
          <h3>CV</h3>
        </a>
      </Link>
      <Link>
        <a href={instagram.url} target="_blank">
          <Instagram />
        </a>
      </Link>
      <Link>
        <a href={email.url}>
          <Mail />
        </a>
      </Link>
      <Link>
        <a href={facebook.url} target="_blank">
          <Facebook />
        </a>
      </Link>
    </Links>

    {/* <pre>
      <code>{JSON.stringify(rest, null, 2)}</code>
    </pre> */}
  </Wrap>
);
//
const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  padding: 1rem;
  text-align: center;
  width: 100vw;

  img {
    max-width: 50px;
    padding-top: 0.5rem;
  }

  h3 {
    font-size: 40px;
    margin: 0 auto;
  }
`;

const Links = styled.div`
  display: flex;
  margin: 0 auto;
`;
const Link = styled.div`
  width: 40px;
  height: 40px;
  margin: 10px;
  svg {
    width: 100%;
  
    :hover {
      fill: darkred;
    }
`;
