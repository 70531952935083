import React from "react";

export default function Instagram(props) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="auto"
      viewBox="0 0 12 14"
      {...props}
    >
      <path d="M8 7q0-0.828-0.586-1.414t-1.414-0.586-1.414 0.586-0.586 1.414 0.586 1.414 1.414 0.586 1.414-0.586 0.586-1.414zM9.078 7q0 1.281-0.898 2.18t-2.18 0.898-2.18-0.898-0.898-2.18 0.898-2.18 2.18-0.898 2.18 0.898 0.898 2.18zM9.922 3.797q0 0.297-0.211 0.508t-0.508 0.211-0.508-0.211-0.211-0.508 0.211-0.508 0.508-0.211 0.508 0.211 0.211 0.508zM6 2.078q-0.055 0-0.598-0.004t-0.824 0-0.754 0.023-0.805 0.078-0.559 0.145q-0.391 0.156-0.688 0.453t-0.453 0.688q-0.086 0.227-0.145 0.559t-0.078 0.805-0.023 0.754 0 0.824 0.004 0.598-0.004 0.598 0 0.824 0.023 0.754 0.078 0.805 0.145 0.559q0.156 0.391 0.453 0.688t0.688 0.453q0.227 0.086 0.559 0.145t0.805 0.078 0.754 0.023 0.824 0 0.598-0.004 0.598 0.004 0.824 0 0.754-0.023 0.805-0.078 0.559-0.145q0.391-0.156 0.688-0.453t0.453-0.688q0.086-0.227 0.145-0.559t0.078-0.805 0.023-0.754 0-0.824-0.004-0.598 0.004-0.598 0-0.824-0.023-0.754-0.078-0.805-0.145-0.559q-0.156-0.391-0.453-0.688t-0.688-0.453q-0.227-0.086-0.559-0.145t-0.805-0.078-0.754-0.023-0.824 0-0.598 0.004zM12 7q0 1.789-0.039 2.477-0.078 1.625-0.969 2.516t-2.516 0.969q-0.688 0.039-2.477 0.039t-2.477-0.039q-1.625-0.078-2.516-0.969t-0.969-2.516q-0.039-0.688-0.039-2.477t0.039-2.477q0.078-1.625 0.969-2.516t2.516-0.969q0.688-0.039 2.477-0.039t2.477 0.039q1.625 0.078 2.516 0.969t0.969 2.516q0.039 0.688 0.039 2.477z" />
    </svg>
  );
}
