import React from "react";

export default function EnvelopeO(props) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="auto"
      viewBox="0 0 14 15"
      {...props}
    >
      <path d="M13 11.75v-6q-0.25 0.281-0.539 0.516-2.094 1.609-3.328 2.641-0.398 0.336-0.648 0.523t-0.676 0.379-0.801 0.191h-0.016q-0.375 0-0.801-0.191t-0.676-0.379-0.648-0.523q-1.234-1.031-3.328-2.641-0.289-0.234-0.539-0.516v6q0 0.102 0.074 0.176t0.176 0.074h11.5q0.102 0 0.176-0.074t0.074-0.176zM13 3.539v-0.191t-0.004-0.102-0.023-0.098-0.043-0.070-0.070-0.059-0.109-0.020h-11.5q-0.102 0-0.176 0.074t-0.074 0.176q0 1.312 1.148 2.219 1.508 1.187 3.133 2.477 0.047 0.039 0.273 0.23t0.359 0.293 0.348 0.246 0.395 0.215 0.336 0.070h0.016q0.156 0 0.336-0.070t0.395-0.215 0.348-0.246 0.359-0.293 0.273-0.23q1.625-1.289 3.133-2.477 0.422-0.336 0.785-0.902t0.363-1.027zM14 3.25v8.5q0 0.516-0.367 0.883t-0.883 0.367h-11.5q-0.516 0-0.883-0.367t-0.367-0.883v-8.5q0-0.516 0.367-0.883t0.883-0.367h11.5q0.516 0 0.883 0.367t0.367 0.883z" />
    </svg>
  );
}
