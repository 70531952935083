import React from "react";
import { Link } from "@reach/router";
import styled from "styled-components";
//
// this is only active when the location pathname is exactly
// the same as the href.
const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: "active" } : null;
};

const ExactNavLink = props => <Link getProps={isActive} {...props} />;

// this link will be active when itself or deeper routes
// are current
const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent ? { className: "active" } : null;
};

const PartialNavLink = props => (
  <Link getProps={isPartiallyActive} {...props} />
);
export default ({ mobileActive, onClick, ...rest }) => (
  <Wrap>
    <ExactNavLink
      to="/"
      title="Eliya Zweygberg"
      onClick={mobileActive && onClick}
      {...rest}
    >
      <h1>Eliya Zweygberg</h1>
    </ExactNavLink>
  </Wrap>
);
//
const Wrap = styled.div`
  margin: 0 auto;
  text-transform: uppercase;
`;
