import React from "react";

export default function FacebookSquare(props) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="auto"
      viewBox="0 0 12 14"
      {...props}
    >
      <path d="M9.75 1q0.93 0 1.59 0.66t0.66 1.59v7.5q0 0.93-0.66 1.59t-1.59 0.66h-1.469v-4.648h1.555l0.234-1.812h-1.789v-1.156q0-0.438 0.184-0.656t0.715-0.219l0.953-0.008v-1.617q-0.492-0.070-1.391-0.070-1.062 0-1.699 0.625t-0.637 1.766v1.336h-1.563v1.812h1.563v4.648h-4.156q-0.93 0-1.59-0.66t-0.66-1.59v-7.5q0-0.93 0.66-1.59t1.59-0.66h7.5z" />
    </svg>
  );
}
